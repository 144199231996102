import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fd9b8b6dc7abe4abe941c37db1fb48b95@o220394.ingest.sentry.io\u002F5623487",
    environment:"production",
    release:"1.327.0-hotfix.0",
    clientIntegrations:{"Vue":{"attachProps":true}},
    serverIntegrations:{"CaptureConsole":{"levels":["error"]}},
    beforeSend:function (event) {
        const headers = event?.request?.headers;
        if (!headers) {
          return event;
        }

        for (const header in headers) {
          if (header.toLowerCase() === 'securityscan' && headers?.[header] === 'g1bb3r1sh') {
            return null;
          }
        }

        return event;
      }
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
